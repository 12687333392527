.app {
	@apply .flex .h-full .flex-col {}

	// .loader
	// 	width: 100%
	// 	height: 100%
	// 	max-width: 90%
	// 	max-height: 90%
	// 	opacity: 0.02
	// 	background-image: url(/img/ok-ok-logo-white.svg)
	// 	background-position: center
	// 	background-repeat: no-repeat
	// 	position: absolute
	// 	left: 50%
	// 	top: 50%
 }	// 	transform: translate(-50%, -50%)

.barba-container a:hover {
	@apply underline {} }

header,
footer {
	transition: background-color 0.3s ease, color 0.3s ease; }

header {
	@apply .border-b {}
	border-color: initial;
	@screen md {
		@apply .flex .justify-between {} }

	.logo {
		@apply .block {}
		padding: 0.35rem;
		svg {
			height: 100%;
			width: auto;
			fill: currentColor;

			.logo_k {
				transform-origin: center;
				transform-box: fill-box;
				&.k1 {
					animation: spin 2.5s ease-in-out infinite; }
				&.k2 {
					animation: spin_delay 2.5s ease-in-out infinite; } }

			@screen before-stack {
				height: 1.5rem; } } } }

footer {
	@apply .border-t .flex .flex-row flex-wrap {}
	border-color: initial;
	transition: transform 1s $easeInOutQuart;
	transform: translateY(100%);
	@screen md {
		@apply .flex .justify-between .flex-row {} }

	&.show {
		transform: translateY(0%); }

	#projectInfo {
		@apply .flex .items-center order-2 cursor-pointer {}
		flex-basis: 50%;
		transition: opacity 0.6s $easeInOutQuart;
		border-color: initial;
		&:hover {
			@apply underline {} }
		@screen md {
			@apply order-1 {}
			flex-basis: 20%; }

		&.fade-out {
			@apply .opacity-0 {} }

		&.hide {
			display: none; } }

	#workTitle {
		@apply .flex .opacity-100 .items-center .justify-center order-1 {}
		transition: opacity 0.3s ease;
		flex-basis: 100%;
		@screen md {
			@apply order-2 {}
			flex-basis: 60%; }
		@screen before-stack {
			@apply .border-b .text-center {}
			border-color: initial;
			span {
				@apply .block {} } }

		&.fade-out {
			@apply .opacity-0 {} }

		&.hide {
			display: none; } }

	#projectNo {
		@apply .flex .items-center .justify-end order-3 {}
		flex-basis: 50%;
		transition: opacity 0.3s ease;
		@screen md {
			flex-basis: 20%; }

		&.fade-out {
			@apply .opacity-0 {} }

		&.hide {
			display: none; } } }

header ul.nav,
footer .credits,
footer #workTitle span,
footer #projectNo span,
footer #projectInfo {
	@apply .p-05 {} }

#barba-wrapper {
	@apply .flex-1 .flex .flex-col .overflow-y-auto {}

	.barba-container {
		@apply .flex .flex-1 .flex-col {}

		&#default {
			@apply .bg-white .text-black .py-1 .px-15 {} } } }


.container {
	@apply .mx-auto .w-full {}
	max-width: 1200px;
	@screen after-stack {
		@apply .text-lg .my-2 {} }

	p {
		@apply .my-1 {}
		@screen after-stack {
			@apply .my-15 {} } } }

.barba-container#work {
	overflow-y: hidden;
	position: relative; }

#project-info {
	@apply .absolute .inset-0 .flex .z-50 overflow-y-auto border-t {}
	transform: translateY(100%);
	transition: transform 0.6s $easeInOutQuart, border-top 0s ease 0s;

	&.open {
		@apply border-t-0 {}
		transition: transform 0.6s $easeInOutQuart, border-top 0s ease 0.6s;
		transform: translateY(0%); }

	#projectInfo-container {
		@apply text-center p-1 overflow-y-auto m-auto {}
		opacity: 0;
		transition: opacity 0.3s ease;
		max-width: 1200px;
		@screen after-stack {
			@apply .text-lg {} }

		&.show {
			opacity: 1; }

		h2 {
			@apply underline {} }
		p, ul {
			@apply .mb-1 {} }
		ul, ol {
			@apply mx-auto flex flex-wrap justify-center {}

			li {
				@apply mx-025 {} }

			p {
				@apply whitespace-no-wrap mb-0 {} } } } }

.slideshow {
	@apply .h-full .w-full .opacity-0 .overflow-x-hidden {}
	transition: opacity 1s ease;

	&.ready {
		@apply .opacity-100 {} }

	.slide {
		position: absolute;
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
		transition: opacity 0.3s ease;
		opacity: 1;

		&.hide {
			opacity: 0; } }

	.flickity-slider {
		@apply .flex .items-center {} } }

.prev {
	@apply .absolute .inset-y-0 .left-0 .h-full .w-1/2 .z-20 {}
	@screen after-stack {
		cursor: url("/img/ok-ok-arrow-left.svg") 0 0, pointer;
		cursor: url("/img/ok-ok-arrow-left.png") 0 0, pointer;
		cursor: -webkit-image-set(url("/img/ok-ok-arrow-left.svg") 1x, url("/img/ok-ok-arrow-left@2x.svg") 2x) 0 0, pointer; }
	@screen before-stack {
		@apply .hidden {} } }

.next {
	@apply .absolute .inset-y-0 .right-0 .h-full .w-1/2 .z-20 {}
	@screen after-stack {
		cursor: url("/img/ok-ok-arrow-right.svg") 0 0, pointer;
		cursor: url("/img/ok-ok-arrow-right.png") 0 0, pointer;
		cursor: -webkit-image-set(url("/img/ok-ok-arrow-right.svg") 1x, url("/img/ok-ok-arrow-right@2x.svg") 2x) 0 0, pointer; }
	@screen before-stack {
		@apply .hidden {} } }

@keyframes spin {
	0% {
		transform: rotate(0deg); }
	55% {
		transform: rotate(0deg); }
	100% {
		transform: rotate(360deg); } }
@keyframes spin_delay {
	0% {
		transform: rotate(0deg); }
	45% {
		transform: rotate(360deg); }
	100% {
		transform: rotate(360deg); } }
