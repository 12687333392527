//typography
h1, h2, h3, h4 {
 }	// @apply .font-headings .text-lg .mb-1

.container {
	ul {
		li {
			@apply inline-block pr-05 {}
			&:last-child {
				@apply pr-0 {} }
			p {
				margin: 0 !important; } } }

	p + ul {
		margin-top: -1rem;
		@screen after-stack {
			margin-top: -1.5rem; } } }
