//nav
ul.nav {
	@apply .flex .justify-end {}
	@screen before-stack {
		@apply .border-t {}
		border-color: initial; }

	li {
		&.active > a,
		&:hover {
			@apply .underline {} }

		a {
			@apply .px-05 {} }

		&:last-child {
			a {
				@apply .pr-0 {} } } } }
