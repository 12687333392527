//base html stuff
*, ::before, ::after {
	border-color: inherit; }
html,
body {
	@apply .bg-black .text-white .font-body .h-full .min-h-full .antialiased .leading-tight {}
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeLegibility;

	font-size: 100%;
	@screen md {
		font-size: 110%; } }

body {
	transition: background-color 0.3s ease; }

body.fixed {
	position: fixed;
	overflow: hidden;
	width: 100%; }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

img {
	display: block;
	width: 100%; }

#preload-bar {
	@apply .bg-black .fixed .w-full .top-0 .left-0 .right-0 .z-30 {}
	height: 1px;

	svg {
		height: 1px;
		width: 0px; }

	path {
		@apply .stroke-current .text-white {} } }

#preload-counter {
	@apply .fixed .z-30 .top-0 .right-0 .mt-05 .mr-05 .text-white {} }

#preload-overlay {
	@apply .bg-black .fixed .w-full .h-full .top-0 .left-0 .z-20 .opacity-100 {} }

#barba-wrapper {
	@apply .relative .z-10 {} }

.barba-container {
	@apply .w-full {} }
